import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import Grid from "@material-ui/core/Grid";

import style from "../../components/Dashboards/style";
import {checkAuth} from "../../helpers/auth";
import { GroupBy, groupByOptions } from "./sales";

import axios from "axios";

import moment from 'moment';
import ReportsFiltering from "../../components/ReportsFiltering";
import Results from "../../components/DashboardResults";
import ScrollTopLeft from "../../components/Miscellaneous/scrollTopLeft";


class LinksClicked extends React.Component {
    results = React.createRef();
    filtering = React.createRef();
    state = {
        config: null,
        selectedDimensions: {
            startDate: moment().startOf('week').add('days', 1).format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD')
        },
        additionalFilters: {},
        columns: [],
        data: [],
        groupBy: 'Day'
    }
    componentDidMount() {
        checkAuth('media-planner');
        axios.get(`${process.env.SERVER_ENDPOINT}/reportsConfig/links-clicked`).then(response => {
            this.setState({
                ...this.state,
                config: response.data,
            })
        })
    }

    onSelect(selected, additionalFilters) {
        this.setState({
            ...this.state,
            selectedDimensions: selected,
            additionalFilters: additionalFilters
        }, () => {
            this.results.current.loadData();
        })
        
    }

    getDimensionParams() {
        let str = '';
        for(let i in this.state.selectedDimensions) {
            if(typeof(this.state.selectedDimensions[i]) !== 'undefined') {
                if(typeof(this.state.selectedDimensions[i]) === 'string') {
                    str += `&${encodeURIComponent(i)}=${this.state.selectedDimensions[i]}`;
                } else {
                    str += `&${encodeURIComponent(i)}=${this.state.selectedDimensions[i].join(`&${i}=`)}`;
                }
            }
        }

        if(Object.keys(this.state.additionalFilters).length > 0) {
            for(let i in this.state.additionalFilters) {
                str += `&${encodeURIComponent(i)}=${this.state.additionalFilters[i]}`;
            }
        }
        return str;
    }

    groupBy(value) {
        let dims = Object.assign({}, this.state.selectedDimensions);
        switch(value) {
            case 'Day':
                dims.startDate = moment().add('day',  -7).format('YYYY-MM-DD')
                dims.endDate = moment().format('YYYY-MM-DD')
            break;

            case 'Week':
                dims.startDate = moment().add('week', -5).format('YYYY-MM-DD')
                dims.endDate = moment().format('YYYY-MM-DD')
            break;

            case 'Month':
                dims.startDate = moment().add('month', -12).format('YYYY-MM-DD')
                dims.endDate = moment().format('YYYY-MM-DD')
            break;

            case 'Day of Week':
                dims.startDate = moment().add('days', -30).format('YYYY-MM-DD')
                dims.endDate = moment().format('YYYY-MM-DD')
            break;
        }
        this.filtering.current.setState({
            ...this.filtering.current.state,
            selected: dims
        });
        this.setState({
            ...this.state,
            groupBy: value,
            selectedDimensions: dims
        })
    }

    render() {
        let cols = [];
        if(this.state.columns.length > 0) {
            cols = this.getColumns();
        }
        return (
            <Layout>
                <SEO title="Welcome"/>
                <Grid container style={style.mainContainer}>
                    <Grid container justify="center" spacing={2}>
                        {this.state.config !== null ? 
                        <div style={style.tabContainer}>
                            
                            <Results
                                    ref={this.results}
                                    sort={false}
                                    columns={[]}
                                    dynamicColumns={true}
                                    dynamicDrillDownColumns={true}
                                    drillDownOptions={{
                                        columns: [],
                                        queryParams: `${this.getDimensionParams() }&groupByTimePeriod=${this.state.groupBy}`
                                    }}
                                    drillDownFormatting={this.state.config.drillDownFormatting}

                                    url={process.env.SERVER_ENDPOINT + this.state.config.url + `?${this.getDimensionParams() }&groupByTimePeriod=${this.state.groupBy}`}
                                    exportCSVConfig={{
                                        name:'links-clicked',
                                        url: process.env.SERVER_ENDPOINT + this.state.config.url + `?${this.getDimensionParams() }&groupByTimePeriod=${this.state.groupBy}`
                                    }}
                                    filters={{
                                        selected: this.state.selectedDimensions, 
                                        additionalFilters: this.state.additionalFilters
                                    }}
                                    dimensions={this.state.config.dimensions}
                                >
                                    <h3>{this.state.config.title}</h3>
                                    <ReportsFiltering
                                        ref={this.filtering}
                                        onSelect={(selected, filters) => this.onSelect(selected, filters)}
                                        selected={{
                                            startDate: this.state.selectedDimensions.startDate,
                                            endDate: this.state.selectedDimensions.endDate
                                        }}
                                        type="links-clicked"
                                        config={this.state.config}>
                                            <GroupBy groupBy={this.state.groupBy} groupByFn={this.groupBy.bind(this)} options={groupByOptions}></GroupBy>
                                    </ReportsFiltering>
                            </Results>
                            <ScrollTopLeft />
                        </div>
                        : null}
                    </Grid>
                </Grid>
            </Layout>
        );
    }
}

export default LinksClicked;